@import '~@aurora/typography/mixins';
@import '~@aurora/theme/mixins';
@import '~@aurora/rtl/mixins';
@import './variables';

@mixin a-button-reset {
    padding: 0;
    cursor: pointer;
    color: inherit;
    border: 0;
    background-color: transparent;
    font: inherit;
}

@mixin a-button-base {
    @include a-button-reset();
    @include a-typography(button);

    display: inline-flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: 80px;
    height: $a-button-height;
    appearance: none;
    padding: 0 $a-button-horizontal-padding;
    user-select: none;
    transition: background-color $a-button-transition;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: $a-button-corner-radius;
    line-height: inherit;

    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
    
    &:hover {
        cursor: pointer;
    }
    
    &:disabled,
    &[disabled],
    &[aria-disabled='true'] {
        cursor: default;
        pointer-events: none;
        opacity: .4;
    }
}

@mixin a-button-filled-hover($background-theme-prop:primary, $color-theme-prop:on-primary) {
    &:hover:not(disabled),
    &:focus:not(disabled) {
        @include a-theme-prop(color, $color-theme-prop);
        
        background-color: darken(a-theme-prop-value($background-theme-prop), 10%);
    }
  
    &:active {
        background-color: darken(a-theme-prop-value($background-theme-prop), 20%);
    }
}

@mixin a-button-transparent-hover($color-theme-prop:primary) {
    &:hover:not(disabled),
    &:focus:not(disabled) {
        text-decoration: underline;
        color: darken(a-theme-prop-value($color-theme-prop), 10%);
    }
}

@mixin a-button-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    vertical-align: top;
    font-size: 18px;
    fill: currentColor;

    &:first-child {
        @include a-rtl-reflexive-box(margin, right, 10px);
    }

    &:last-child {
        @include a-rtl-reflexive-box(margin, left, 10px);
    }

    &:only-child {
        margin: 0;
    }
}
