/** @define loader */
@import '~@aurora/theme/mixins';
@import './mixins';

.a-loader {
    @include a-loader-base;
}

.a-loader-bar {
    @include a-loader-bar;
}
