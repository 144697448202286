@import '~@aurora/theme/a-theme';

@mixin a-listbox {
    @include a-theme-prop(background-color, surface);
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin a-listbox-item {
    padding: 1rem;
    cursor: default;

    &[aria-selected='true'] {
        @include a-theme-prop(background-color, primary);
        @include a-theme-prop(color, on-primary);
    }

    &:hover:not([aria-selected='true']) {
        @include a-theme-prop(background-color, grey-background);
    }
}

