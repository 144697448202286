@import '~@aurora/theme/mixins';
@import '~@aurora/icon/mixins';
@import '~@aurora/typography/mixins';
@import '~@aurora/rtl/mixins';
@import './variables';

@mixin a-alert-base {
    @include a-theme-prop(color, text-primary);
    @include a-typography(body2); 
    
    display: flex;
    justify-content: space-between;
    padding: $a-alert-vertical-padding $a-alert-horizontal-padding;
    word-wrap: break-word;
    overflow-wrap: break-word;
    border: 1px solid transparent;
    border-radius: $a-alert-corner-radius;
}

@mixin a-alert-icon {
    @include a-icon-size(s);
    @include a-icon(currentColor);

    flex: 0 0 auto;
    margin-top: 1px;

    &:first-child {
        @include a-rtl-reflexive-box(margin, right, 10px);
    }

    &:last-child {
        @include a-rtl-reflexive-box(margin, left, 10px);
    }
}
