@import '~@aurora/icon/a-icon';
@import '~@aurora/typography/a-typography';

.infoPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
}

.infoPanel-body {
    text-align: center;
}

.infoPanel-actions {
    margin: 25px 0;
}
