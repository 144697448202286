@mixin a-loader-base {
    position: relative;

    &--bars {
        width: 85px;
        height: 50px;
    }
}

@mixin a-loader-bar {
    position: absolute;
    top: 15px;
    width: 5px;
    height: 20px;
    border-radius: 3px;

    &--even {
        @include a-theme-prop(background-color, grey-stroke);
    }
    
    &--odd {
        @include a-theme-prop(background-color, grey-background);
    }

    @for $i from 1 through 9 {
        &:nth-child(#{$i}) {
            left: ($i - 1) * 10px;
            animation: bar#{$i} 1s ease-in-out infinite;
        }
    }
    
    @for $i from 1 through 9 {
        @keyframes bar#{$i} {
            #{$i * 10%} {
                top: 0;
                height: 50px;
            }

            #{($i * 10 + 20) * 1%} {
                top: 15px;
                height: 20px;
            }
        }
    }
}
