@import '~@aurora/theme/mixins';
@import '~@aurora/rtl/mixins';
@import '~@aurora/typography/mixins';
@import '~@aurora/form/a-text-input/a-input';
@import '~@aurora/icon/a-icon';

@mixin a-select {
    position: relative;
    display: inline-flex;
}

@mixin a-select-listbox {
    @include a-typography-base;
    @include a-typography(body1);
    @include a-theme-prop(color, text-primary);
    // Double negation is used instead of sum for Combo compressor because it removes spaces before and after +
    // during minification
    max-height: calc(((1rem * 1.5) - -10px) * 6);
    border: 1px solid a-theme-prop-value(primary);
    border-top-width: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    &::-moz-focus-inner {
        border: 0;
    }

    &:focus {
        @include a-theme-prop(border-color, primary);
        outline: none;
    }

    .a-select & {
        position: absolute;
        z-index: 1;
        top: 100%;
        right: 0;
        left: 0;
    }
}

@mixin a-select-listbox-item {
    padding: 5px 10px;
}

@mixin a-select-button {
    @include a-input-base();
    @include a-input-group();
    @include a-theme-prop(background-color, surface);
    @include a-rtl-reflexive-property(padding, 7px, 32px);

    display: block;
    overflow: hidden;
    width: 250px;
    cursor: pointer;
    text-align: left;
    // it is a UX requirement to hide overflowed content without ellipsis
    white-space: nowrap;
    color: inherit;

    @include a-rtl {
        text-align: right;
    }

    &--shown {
        @include a-theme-prop(border-color, primary);

        border-bottom-color: a-theme-prop-value(grey-input);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &::-moz-focus-inner {
        border: 0;
    }

    &:hover:not(disabled),
    &:focus:not(disabled) {
        @include a-theme-prop(color, text-primary);
        @include a-theme-prop(border-color, primary);
        text-decoration: none;
        outline: none;

    }
}

@mixin a-select-button-icon-container {
    @include a-theme-prop(background-color, surface);

    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 100%;

    @include a-rtl {
        right: auto;
        left: 0;
    }
}

@mixin a-select-button-icon {
    transform: scaleY(-1);

    .a-select-button--shown & {
        transform: none;
    }
}

