/** @define alert */
@import './variables';
@import './mixins';

.a-alert {
    @include a-alert-base;
}

.a-alert-text {    
    flex: 1 1 auto;
    width: 100%;
    min-width: 0;		
}

.a-alert-icon {
    @include a-alert-icon;
}

// Modifiers:
.a-alert--info {
    @include a-theme-prop(border-color, state-info); 
    @include a-theme-prop(background-color, state-secondary-info);
}

.a-alert--success {
    @include a-theme-prop(border-color, state-success);
    @include a-theme-prop(background-color, state-secondary-success);
}   

.a-alert--danger {
    @include a-theme-prop(border-color, state-danger);
    @include a-theme-prop(background-color, state-secondary-danger);
}

.a-alert--warning {
    @include a-theme-prop(border-color, state-warning);
    @include a-theme-prop(background-color, state-secondary-warning);   
}

.a-alert-icon--info {
    @include a-theme-prop(fill, state-info);
}

.a-alert-icon--success {
    @include a-theme-prop(fill, state-success);
}

.a-alert-icon--danger {
    @include a-theme-prop(fill, state-danger);
}

.a-alert-icon--warning {
    @include a-theme-prop(fill, state-warning);
}
