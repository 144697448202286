/** @define button */
@import './variables';
@import './mixins';

.a-button {
    @include a-button-base;
    @include a-theme-prop(background-color, transparent);
    @include a-theme-prop(color, primary);
}

.a-button-text {
    display: inline;
}

// Base button modifiers
.a-button--info {
    @include a-theme-prop(color, state-info);
}

.a-button--success {
    @include a-theme-prop(color, state-success);
}

.a-button--danger {
    @include a-theme-prop(color, state-danger);
}

.a-button--warning {
    @include a-theme-prop(color, state-warning);
}

// Primary buttons:
.a-button--primary {
    @include a-theme-prop(background-color, primary);
    @include a-theme-prop(color, on-primary);
    @include a-button-filled-hover;
}

.a-button--primarySuccess {
    @include a-theme-prop(background-color, state-success);
    @include a-theme-prop(color, on-primary);
    @include a-button-filled-hover(state-success);
}

.a-button--primaryInfo {
    @include a-theme-prop(background-color, state-info);
    @include a-theme-prop(color, on-primary);
    @include a-button-filled-hover(state-info);
}

.a-button--primaryDanger {
    @include a-theme-prop(background-color, state-danger);
    @include a-theme-prop(color, on-primary);
    @include a-button-filled-hover(state-danger);
}

.a-button--primaryWarning {
    @include a-theme-prop(background-color, state-warning);
    @include a-theme-prop(color, on-primary);
    @include a-button-filled-hover(state-warning);
}

// Outlined buttons:
.a-button--outlined {
    @include a-theme-prop(border-color, primary);
    @include a-button-filled-hover;
}

.a-button--outlinedInfo {
    @include a-theme-prop(border-color, state-info);
    @include a-theme-prop(color, state-info);
    @include a-button-filled-hover(state-info);
}

.a-button--outlinedSuccess {
    @include a-theme-prop(border-color, state-success);
    @include a-theme-prop(color, state-success);
    @include a-button-filled-hover(state-success);
}

.a-button--outlinedDanger {
    @include a-theme-prop(border-color, state-danger);
    @include a-theme-prop(color, state-danger);
    @include a-button-filled-hover(state-danger);
}

.a-button--outlinedWarning {
    @include a-theme-prop(border-color, state-warning);
    @include a-theme-prop(color, state-warning);
    @include a-button-filled-hover(state-warning);
}

// Transparent buttons:
.a-button--transparent {
    @include a-button-transparent-hover;
}

.a-button--transparentInfo {
    @include a-theme-prop(color, state-info);
    @include a-button-transparent-hover(state-info);
}

.a-button--transparentSuccess {
    @include a-theme-prop(color, state-success);
    @include a-button-transparent-hover(state-success);
}

.a-button--transparentDanger {
    @include a-theme-prop(color, state-danger);
    @include a-button-transparent-hover(state-danger);
}

.a-button--transparentWarning {
    @include a-theme-prop(color, state-warning);
    @include a-button-transparent-hover(state-warning);
}

.a-button-icon {
    @include a-button-icon;
}
